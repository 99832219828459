import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  ResponseSearchFilterModelDto,
  ResponseSearchResultItemDto,
  ReplaceResponseModelDto,
  TextHighlightType,
  ReplaceAllResponseModelDto,
} from 'src/app/response-management/proxy/response-management.model';
import { convertRichTextToPlainText } from 'src/app/shared/designer/utils';
import { RichTextEditorComponent } from 'src/app/shared/rich-text/components/rich-text-editor/rich-text-editor.component';
import { ResponseManagementService } from '../../proxy/response-management.service';
import { Subscription, finalize } from 'rxjs';
import XRegExp from 'xregexp';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from '@abp/ng.theme.shared';
import { TOASTER_LIFE } from 'src/app/shared/shared.consts';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'cai-response-management-list',
  templateUrl: './response-management-list.component.html',
  styleUrls: ['./response-management-list.component.scss'],
})
export class ResponseManagementListComponent implements OnChanges, OnInit, OnDestroy {
  rowsForm: UntypedFormGroup;
  @Input() filterValue: ResponseSearchFilterModelDto;
  @ViewChildren('richTextInput') richTextInputs: QueryList<RichTextEditorComponent>;

  occurrencesDictionary: { [key: number]: number[] } = {};
  currentSelectionRowIndex = 0;
  currentSelectionOccurrenceIndex = 0;
  totalOccurrenceCount = 0;

  resultHighlightColor = 'rgb(255, 255, 0)';
  selectedHighlightColor = 'rgb(173, 216, 230)';
  backgroundProperty = 'background';

  isFirstDataLoad = true;
  toggleGridData = false;

  @BlockUI('results-grid-block-ui') blockUI: NgBlockUI;

  previousResultSelectionRequestedSubjectSubscription: Subscription;
  nextResultSelectionRequestedSubjectSubscription: Subscription;
  replaceRequestedSubjectSubscription: Subscription;
  replaceAllRequestedSubjectSubscription: Subscription;

  tableDataSource: ResponseSearchResultItemDto[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private responseManagementService: ResponseManagementService,
    private cdr: ChangeDetectorRef,
    private toaster: ToasterService,
    private localizationService: LocalizationService
  ) {}

  ngOnDestroy(): void {
    this.previousResultSelectionRequestedSubjectSubscription?.unsubscribe();
    this.nextResultSelectionRequestedSubjectSubscription?.unsubscribe();
    this.replaceAllRequestedSubjectSubscription?.unsubscribe();
    this.replaceRequestedSubjectSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterValue && changes.filterValue.currentValue) {
      this.getSearchResults();
    }
  }

  ngOnInit(): void {
    this.previousResultSelectionRequestedSubjectSubscription =
      this.responseManagementService.previousResultSelectionRequestedSubject.subscribe(() => {
        this.selectPreviousResult();
      });

    this.nextResultSelectionRequestedSubjectSubscription =
      this.responseManagementService.nextResultSelectionRequestedSubject.subscribe(() => {
        this.selectNextResult();
      });

    this.replaceRequestedSubjectSubscription =
      this.responseManagementService.replaceRequestedSubject.subscribe((replaceWith) => {
        this.replace(replaceWith);
      });

    this.replaceAllRequestedSubjectSubscription =
      this.responseManagementService.replaceAllRequestedSubject.subscribe((replaceWith) => {
        this.replaceAll(replaceWith);
      });

    this.rowsForm = this.fb.group({});
  }

  prepareReplaceResponseModel(
    rowToReplace: ResponseSearchResultItemDto,
    replaceWith: string,
    matchIndex: number
  ) {
    const replaceModel: ReplaceResponseModelDto = {
      matchIndex: matchIndex,
      textId: rowToReplace.textId,
      flowId: rowToReplace.flowId,
      nodeTypeId: rowToReplace.nodeTypeId,
      responseTypeId: rowToReplace.responseTypeId,
      oldText: this.filterValue.text,
      newText: replaceWith,
    };

    return replaceModel;
  }

  prepareReplaceAllResponseModel(rowToReplace: ResponseSearchResultItemDto, replaceWith: string) {
    const replaceAllModel: ReplaceAllResponseModelDto = {
      textId: rowToReplace.textId,
      flowId: rowToReplace.flowId,
      nodeTypeId: rowToReplace.nodeTypeId,
      responseTypeId: rowToReplace.responseTypeId,
      oldText: this.filterValue.text,
      newText: replaceWith,
    };

    return replaceAllModel;
  }

  replaceAll(replaceWith: string) {
    if (!this.blockUI.isActive) {
      this.blockUI.start();
    }

    const replaceAllModels: ReplaceAllResponseModelDto[] = [];

    this.tableDataSource.forEach((rowToReplace) => {
      const replaceModel = this.prepareReplaceAllResponseModel(rowToReplace, replaceWith);
      replaceAllModels.push(replaceModel);
    });

    this.responseManagementService
      .replaceAll(replaceAllModels)
      .pipe(
        finalize(() => {
          this.responseManagementService.replaceAllOperationCompletedSubject.next(
            this.tableDataSource.length
          );
        })
      )
      .subscribe({
        next: (replacedOccurrencesCount) => {
          this.toaster.success('ResponseManagement::ReplaceAllCompleted', null, {
            life: TOASTER_LIFE,
            messageLocalizationParams: [replacedOccurrencesCount.toString()],
          });

          this.getSearchResults();
        },
        error: (error) => {
          this.responseManagementService.replaceAllOperationFailedSubject.next();

          this.toaster.error('ResponseManagement::ReplaceError', null, {
            life: TOASTER_LIFE,
          });
          this.blockUI.stop();
          console.log(error);
        },
      });
  }

  replace(replaceWith: string) {
    if (!this.blockUI.isActive) {
      this.blockUI.start();
    }

    const rowToReplace = this.tableDataSource[this.currentSelectionRowIndex];
    const occurrenceIndexInsideRow = this.findOccurrenceIndexInsideRow();

    const replaceModel = this.prepareReplaceResponseModel(
      rowToReplace,
      replaceWith,
      occurrenceIndexInsideRow + 1
    );

    this.responseManagementService
      .replace(replaceModel)
      .pipe(
        finalize(() => {
          this.blockUI.stop();
          this.responseManagementService.replaceOperationCompletedSubject.next(
            this.tableDataSource.length
          );
        })
      )
      .subscribe({
        next: (replacedContent) => {
          const responsePlainTextContent = convertRichTextToPlainText(
            JSON.stringify(replacedContent),
            true
          );

          const occurrences: number[] = this.findAllOccurrenceIndexes(
            responsePlainTextContent,
            this.filterValue.text,
            this.filterValue.matchCase,
            this.filterValue.matchWholeWord
          );

          if (occurrences.length === 0) {
            this.tableDataSource.splice(this.currentSelectionRowIndex, 1);
            this.tableDataSource = [...this.tableDataSource];

            if (this.currentSelectionRowIndex === this.tableDataSource.length) {
              this.currentSelectionRowIndex--;
              this.currentSelectionOccurrenceIndex--;
            }
          } else {
            rowToReplace.text = JSON.stringify(replacedContent);
            if (this.currentSelectionOccurrenceIndex === this.totalOccurrenceCount - 1) {
              this.currentSelectionOccurrenceIndex = 0;
              this.currentSelectionRowIndex = 0;
            }
          }

          this.tableDataSource = [...this.tableDataSource];

          setTimeout(() => {
            this.highlightSearchResults();
            this.highlightOccurrence(
              this.currentSelectionOccurrenceIndex,
              TextHighlightType.selected
            );
          }, 0);
        },
        error: (error) => {
          this.toaster.error('ResponseManagement::ReplaceError', null, {
            life: TOASTER_LIFE,
          });
          console.log(error);
        },
      });
  }

  selectNextResult() {
    if (this.currentSelectionOccurrenceIndex + 1 === this.totalOccurrenceCount) {
      this.currentSelectionOccurrenceIndex = 0;
      this.highlightOccurrence(this.totalOccurrenceCount - 1, TextHighlightType.result);
      this.highlightOccurrence(0, TextHighlightType.selected);
    } else {
      this.highlightOccurrence(this.currentSelectionOccurrenceIndex, TextHighlightType.result);
      this.currentSelectionOccurrenceIndex++;
      this.highlightOccurrence(this.currentSelectionOccurrenceIndex, TextHighlightType.selected);
    }

    const currentRichTextInput = this.richTextInputs.toArray()[this.currentSelectionRowIndex];
    currentRichTextInput.editor.elementRef.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }

  selectPreviousResult() {
    if (this.currentSelectionOccurrenceIndex === 0) {
      this.currentSelectionOccurrenceIndex = this.totalOccurrenceCount - 1;
      this.highlightOccurrence(0, TextHighlightType.result);
      this.highlightOccurrence(this.totalOccurrenceCount - 1, TextHighlightType.selected);
    } else {
      this.highlightOccurrence(this.currentSelectionOccurrenceIndex, TextHighlightType.result);
      this.currentSelectionOccurrenceIndex--;
      this.highlightOccurrence(this.currentSelectionOccurrenceIndex, TextHighlightType.selected);
    }

    const currentRichTextInput = this.richTextInputs.toArray()[this.currentSelectionRowIndex];
    currentRichTextInput.editor.elementRef.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  updateSearchResultsData() {
    if (!this.blockUI.isActive) {
      this.blockUI.start();
    }

    this.tableDataSource = [];

    this.responseManagementService
      .search(this.filterValue)
      .pipe(
        finalize(() => {
          this.blockUI.stop();
          this.responseManagementService.searchResultsLoadedSubject.next(
            this.tableDataSource.length
          );
        })
      )
      .subscribe((searchResult) => {
        console.log(searchResult);
        this.tableDataSource = JSON.parse(JSON.stringify(searchResult));
        this.tableDataSource = [...this.tableDataSource];
        this.cdr.detectChanges();

        setTimeout(() => {
          this.highlightSearchResults();
          this.setFirstOccurrenceAsSelected();
        }, 0);
      });
  }

  findOccurrenceIndexInsideRow() {
    let occurrenceCountSoFar = 0;

    for (const key in this.occurrencesDictionary) {
      if (Object.prototype.hasOwnProperty.call(this.occurrencesDictionary, key)) {
        const dictionaryItem = this.occurrencesDictionary[key];

        if (
          this.currentSelectionOccurrenceIndex >= occurrenceCountSoFar &&
          this.currentSelectionOccurrenceIndex < occurrenceCountSoFar + dictionaryItem.length
        ) {
          // Found it!
          const innerIndex = this.currentSelectionOccurrenceIndex - occurrenceCountSoFar;
          return innerIndex;
        }

        occurrenceCountSoFar += dictionaryItem.length;
      }
    }

    return -1;
  }

  highlightOccurrence(index: number, highlightType: TextHighlightType) {
    let occurrenceCountSoFar = 0;

    for (const key in this.occurrencesDictionary) {
      if (Object.prototype.hasOwnProperty.call(this.occurrencesDictionary, key)) {
        const dictionaryItem = this.occurrencesDictionary[key];

        if (index >= occurrenceCountSoFar && index < occurrenceCountSoFar + dictionaryItem.length) {
          // Found it!
          const innerIndex = index - occurrenceCountSoFar;
          const occurrenceIndex = dictionaryItem[innerIndex];

          const color =
            highlightType === TextHighlightType.result
              ? this.resultHighlightColor
              : this.selectedHighlightColor;

          const relatedRichTextInput = this.richTextInputs.toArray()[key];
          relatedRichTextInput.editor.quillEditor.formatText(
            occurrenceIndex,
            this.filterValue.text.length,
            this.backgroundProperty,
            color
          );

          if (highlightType === TextHighlightType.selected) {
            this.currentSelectionRowIndex = +key;
          }

          return;
        }

        occurrenceCountSoFar += dictionaryItem.length;
      }
    }
  }

  getSearchResults() {
    this.resetComponentData();
    this.updateSearchResultsData();
  }

  resetComponentData() {
    this.occurrencesDictionary = {};
    this.currentSelectionOccurrenceIndex = 0;
    this.currentSelectionRowIndex = 0;
    this.totalOccurrenceCount = 0;
  }

  findAllOccurrenceIndexes(
    source: string,
    searchStr: string,
    matchCase: boolean,
    matchWholeWord: boolean
  ): number[] {
    if (!searchStr) {
      return []; // Return empty if search string is empty
    }

    // Escape the search string to be treated as literal text
    const escapedSearchStr = XRegExp.escape(searchStr);

    // Build the regex pattern based on the parameters
    let pattern = escapedSearchStr;
    if (matchWholeWord) {
      // Use Unicode property scripts to define word boundaries
      pattern = `(?<![\\p{L}\\p{N}])${pattern}(?![\\p{L}\\p{N}])`;
    }

    const flags = matchCase ? 'gu' : 'giu'; // Add 'u' flag for Unicode support
    const regex = XRegExp(pattern, flags);
    const indexes: number[] = [];

    // Execute the regex and collect all indexes
    XRegExp.forEach(source, regex, (match) => {
      indexes.push(match.index);
    });

    return indexes;
  }

  highlightSearchResults() {
    this.totalOccurrenceCount = 0;
    this.occurrencesDictionary = {};

    this.richTextInputs.forEach((input: RichTextEditorComponent, richTextInputIndex) => {
      const contents = input.editor.quillEditor.getContents();
      const responsePlainTextContent = convertRichTextToPlainText(JSON.stringify(contents), true);

      const occurrences: number[] = this.findAllOccurrenceIndexes(
        responsePlainTextContent,
        this.filterValue.text,
        this.filterValue.matchCase,
        this.filterValue.matchWholeWord
      );

      if (occurrences.length > 0) {
        this.occurrencesDictionary[richTextInputIndex] = [];
        this.totalOccurrenceCount += occurrences.length;
      }

      for (const position of occurrences) {
        input.editor.quillEditor.formatText(
          position,
          this.filterValue.text.length,
          this.backgroundProperty,
          this.resultHighlightColor
        );

        this.occurrencesDictionary[richTextInputIndex].push(position);
      }
    });
  }

  setFirstOccurrenceAsSelected() {
    if (
      Object.keys(this.occurrencesDictionary).length > 0 &&
      Object.values(this.occurrencesDictionary)[0].length > 0
    ) {
      const relatedRichTextInput = this.richTextInputs.toArray()[this.currentSelectionRowIndex];
      relatedRichTextInput.editor.quillEditor.formatText(
        Object.values(this.occurrencesDictionary)[0][0],
        this.filterValue.text.length,
        this.backgroundProperty,
        this.selectedHighlightColor
      );
    }
  }

  getTableMessages() {
    return {
      emptyMessage: this.localizationService.instant('Conversation::NoData'),
      totalMessage: this.localizationService.instant('::Total'),
      selectedMessage: this.localizationService.instant('Conversation::SelectedMessage'),
    };
  }
}
