<div class="row entry-row">
  <div class="col ps-lg-0 overflow-auto me-lg-3">
    <abp-breadcrumb-items [items]="breadcrumbItems"> </abp-breadcrumb-items>
  </div>
</div>

<div class="card full-height bg-white">
  <as-split
    [dir]="isRtl ? 'rtl' : 'ltr'"
    unit="pixel"
    direction="horizontal"
    useTransition="false"
    gutterSize="5"
  >
    <as-split-area size="400" minSize="250" maxSize="400" visible="true" order="0">
      <cai-response-management-filter
        (filtersChanged)="onFiltersChanged($event)"
        [projectId]="projectId"
      ></cai-response-management-filter>
    </as-split-area>

    <as-split-area size="*" visible="true" order="1">
      <cai-response-management-list [filterValue]="filterValue"></cai-response-management-list>
    </as-split-area>
  </as-split>
</div>
