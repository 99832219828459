<div>
  <h2>{{ 'Administration::OpenAiSettings' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="mt-2 fade-in-top">
    <div class="form-group">
      <label for="provider" class="pt-3"
        >{{ 'Administration::OpenAiSettings:Provider' | abpLocalization }} *</label
      >
      <ng-select id="provider" formControlName="provider" (change)="setSelectedProvider()">
        <ng-option *ngFor="let providerOption of providerOptions" [value]="providerOption">
          {{ providerOption }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group">
      <label for="apiKey"
        >{{ 'Administration::OpenAiSettings:APIKey' | abpLocalization }}
        <span *ngIf="!hasExistingApiKey">*</span></label
      >
      <input
        id="apiKey"
        formControlName="apiKey"
        class="form-control"
        type="password"
        autocomplete="new-password"
        [placeholder]="
          hasExistingApiKey
            ? ('Administration::OpenAiSettings:APIKeyPlaceHolder' | abpLocalization)
            : ''
        "
      />
    </div>

    <div class="form-group" *ngIf="isOpenAiSelected()">
      <label for="model">{{ 'Administration::OpenAiSettings:Model' | abpLocalization }} *</label>
      <ng-select id="model" formControlName="model">
        <ng-option *ngFor="let modelOption of modelOptions" [value]="modelOption">
          {{ modelOption }}
        </ng-option>
      </ng-select>
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="serviceUrl"
        >{{ 'Administration::OpenAiSettings:ServiceUrl' | abpLocalization }} *</label
      >
      <input id="serviceUrl" formControlName="serviceUrl" class="form-control" type="text" />
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="deploymentName"
        >{{ 'Administration::OpenAiSettings:DeploymentName' | abpLocalization }} *</label
      >
      <input
        id="deploymentName"
        formControlName="deploymentName"
        class="form-control"
        type="text"
      />
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="resourceName"
        >{{ 'Administration::OpenAiSettings:ResourceName' | abpLocalization }} *</label
      >
      <input id="resourceName" formControlName="resourceName" class="form-control" type="text" />
    </div>

    <div class="form-group" *ngIf="isAzureOpenAiSelected()">
      <label for="embeddingDeployment"
        >{{ 'Administration::OpenAiSettings:EmbeddingDeployment' | abpLocalization }}
        <span *ngIf="isGenerativeQuestionAnsweringFeatureEnabled">*</span></label
      >
      <input
        id="embeddingDeployment"
        formControlName="embeddingDeployment"
        class="form-control"
        type="text"
      />
    </div>

    <div class="form-group">
      <label for="embeddingName">
        {{
          (isOpenAiSelected()
            ? 'Administration::OpenAiSettings:EmbeddingNameForOpenAi'
            : 'Administration::OpenAiSettings:EmbeddingNameForAzureOpenAi'
          ) | abpLocalization
        }}
        <span *ngIf="isGenerativeQuestionAnsweringFeatureEnabled">*</span>
      </label>
      <ng-select id="embeddingName" formControlName="embeddingName">
        <ng-option
          *ngFor="let embeddingNameOption of embeddingNameOptions"
          [value]="embeddingNameOption"
        >
          {{ embeddingNameOption }}
        </ng-option>
      </ng-select>
    </div>

    <span
      ><b
        ><p>{{ 'Administration::OpenAiSettings:Prompts' | abpLocalization }}</p></b
      ></span
    >

    <div class="form-group" *ngIf="isUtteranceGenerationFeatureEnabled">
      <label for="utteranceGeneration">{{
        'Administration::OpenAiSettings:Prompts:UtteranceGeneration' | abpLocalization
      }}</label>
      <input
        id="utteranceGeneration"
        formControlName="utteranceGeneration"
        class="form-control"
        type="text"
        readonly
      />
    </div>
    <div class="form-group" *ngIf="isTextResponseGenerationFeatureEnabled">
      <label for="textResponseGeneration">{{
        'Administration::OpenAiSettings:Prompts:TextResponseGeneration' | abpLocalization
      }}</label>
      <input
        id="textResponseGeneration"
        formControlName="textResponseGeneration"
        class="form-control"
        type="text"
        readonly
      />
    </div>

    <div class="form-group" *ngIf="isConversationSummarizationFeatureEnabled">
      <label for="conversationSummarization">{{
        'Administration::OpenAiSettings:Prompts:ConversationSummarization' | abpLocalization
      }}</label>
      <input
        id="conversationSummarization"
        formControlName="conversationSummarization"
        class="form-control"
        type="text"
        readonly
      />
    </div>

    <div class="form-group" *ngIf="isLiveChatSummarizationFeatureEnabled">
      <label for="liveChatSummarization">{{
        'Administration::OpenAiSettings:LiveChatSummarization' | abpLocalization
      }}</label>
      <input
        id="liveChatSummarization"
        formControlName="liveChatSummarization"
        class="form-control"
        type="text"
        readonly
      />
    </div>

    <div class="form-group" *ngIf="isProjectGenerationFeatureEnabled">
      <label for="projectGeneration">{{
        'Administration::OpenAiSettings:Prompts:ProjectGeneration' | abpLocalization
      }}</label>
      <input
        id="projectGeneration"
        formControlName="projectGeneration"
        class="form-control"
        type="text"
        readonly
      />
    </div>
  </div>
  <abp-button
    (click)="submitForm()"
    iconClass="fa fa-check"
    [loading]="isLoading"
    [disabled]="!isFormValid()"
    class="ms-1"
  >
    {{ 'AbpUi::Save' | abpLocalization }}
  </abp-button>
</form>
