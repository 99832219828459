<div class="filter-container response-filters-wrapper" *blockUI="'filter-panel-block-ui'">
  <form [formGroup]="filterForm" class="filter-form">
    <div>
      <nav class="navbar">
        <div class="title ms-3">
          <span class="h4">
            <i class="bi bi-filter-circle ms-2 mb-1 fa-lg"></i>
            {{ '::Title:SearchAndFilters' | abpLocalization }}
          </span>
        </div>
      </nav>
    </div>
    <div class="filter-panel-accordion d-flex flex-column justify-content-between">
      <div>
        <ngb-accordion
          #accordion="ngbAccordion"
          activeIds="filterResponse, filterFlow, filterNodeType, filterResponseType"
        >
          <ngb-panel id="filterResponse">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:ResponseManagement:Response' | abpLocalization }} *
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="input-group group-control" #searchBox>
                <span class="input-group-text bg-white ps-2 pe-2">
                  <i class="search-icon fa fa-search"></i>
                </span>
                <input
                  autocomplete="off"
                  formControlName="text"
                  type="text"
                  class="form-control form-height-25 custom-input border-0 padding-x-zero"
                  [placeholder]="'ResponseManagement::ResponseInputPlaceholder' | abpLocalization"
                  (keydown)="onKeydownForSearchBox($event)"
                />
                <span
                  class="input-group-search bg-white ms-1 me-0 px-1 c-pointer"
                  [ngClass]="{ 'toggle-button-selected': isMatchCaseSelected }"
                  ngbTooltip="{{ 'ResponseManagement::MatchCase' | abpLocalization }}"
                  [ngClass]="{
                    'disabled-span': showReplaceLoader || showFilterLoader
                  }"
                  (click)="onMatchCaseClicked()"
                >
                  <img src="../../../../assets/images/match-case.svg" />
                </span>
                <span
                  class="input-group-search bg-white ms-1 me-1 px-1 c-pointer"
                  [ngClass]="{ 'toggle-button-selected': isMatchWholeWordSelected }"
                  ngbTooltip="{{ 'ResponseManagement::MatchWholeWord' | abpLocalization }}"
                  [ngClass]="{
                    'disabled-span': showReplaceLoader || showFilterLoader
                  }"
                  (click)="onMatchWholeWordClicked()"
                >
                  <img src="../../../../assets/images/match-whole-word.svg" />
                </span>
                <ng-container *abpPermission="'Designer.Projects.Edit'">
                  <span
                    class="input-group-search bg-white ps-1 pe-2 c-pointer"
                    [ngClass]="{
                      'disabled-span':
                        loadedSearchResultsLength === 0 || showReplaceLoader || showFilterLoader
                    }"
                    [ngbPopover]="popContent"
                    [positionTarget]="searchBox"
                    [popperOptions]="replacePanelPopperOptions"
                    placement="bottom"
                    popoverClass="replace-panel-popover"
                    #replacePanelPopover="ngbPopover"
                    [autoClose]="false"
                    triggers="manual"
                    (click)="replacePanelPopover.open()"
                  >
                    <img
                      src="../../../../assets/images/text-replace.svg"
                      ngbTooltip="{{ 'ResponseManagement::Replace' | abpLocalization }}"
                    />
                  </span>
                </ng-container>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterFlow">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:ResponseManagement:Flow' | abpLocalization }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-select
                notFoundText="{{ '::NotFound' | abpLocalization }}"
                formControlName="flowId"
                [multiple]="false"
                [closeOnSelect]="true"
                [loading]="isFilterFlowLoading"
              >
                <ng-option *ngFor="let item of projectFlows" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterNodeType">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:ResponseManagement:NodeType' | abpLocalization }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-select
                notFoundText="{{ '::NotFound' | abpLocalization }}"
                formControlName="nodeTypeId"
                [multiple]="false"
                [closeOnSelect]="true"
                [loading]="isFilterNodeTypeLoading"
              >
                <ng-option *ngFor="let item of projectNodeTypes" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="filterResponseType">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0 filter-panel-item-title">
                  {{ '::Title:ResponseManagement:ResponseType' | abpLocalization }}
                </h5>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-select
                notFoundText="{{ '::NotFound' | abpLocalization }}"
                formControlName="responseTypeId"
                [multiple]="false"
                [closeOnSelect]="true"
              >
                <ng-option *ngFor="let item of projectResponseTypes" [value]="item.id">
                  {{ 'NodePalette::' + item.name | abpLocalization }}
                </ng-option>
              </ng-select>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <div class="filter-action-buttons">
        <button
          class="btn clear-button w-50 me-2"
          (click)="onClearFilters()"
          [disabled]="showFilterLoader || showReplaceLoader"
        >
          {{ '::Button:Clear' | abpLocalization }}
        </button>
        <button
          placement="top"
          class="btn submit-button w-50 ms-2"
          (click)="onSubmitFilters()"
          [disabled]="
            filterForm?.get('text')?.value?.trim()?.length < 2 ||
            showFilterLoader ||
            showReplaceLoader
          "
        >
          <i class="far fa-filter ms-2 me-2"></i>
          <span>{{ '::Button:Filter' | abpLocalization }}</span>
          <i *ngIf="showFilterLoader" class="fa fa-spinner fa-pulse fa-fw ms-1"></i>
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #popContent>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-content-end">
      <span class="ps-1 pe-2 c-pointer close-button" (click)="replacePanelPopover.close()">
        x
      </span>
    </div>
    <div>
      <div class="row">
        <form [formGroup]="replaceForm" (ngSubmit)="onSubmitReplaceForm()">
          <div class="mt-2 fade-in-top">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group d-flex flex-row">
                  <label class="pe-2 label-sm text-nowrap"
                    >{{ 'ResponseManagement::ReplaceWith' | abpLocalization }}
                  </label>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control replace-text-box"
                    formControlName="replaceWith"
                    autofocus
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-end">
      <i
        class="fa-solid fa-chevron-left navigation-button"
        [ngClass]="{
          'disabled-span': showReplaceLoader || showFilterLoader || loadedSearchResultsLength === 0
        }"
        (click)="onPreviousSearchResultClicked()"
      ></i>
      <i
        class="fa-solid fa-chevron-right ms-1 me-2 navigation-button"
        [ngClass]="{
          'disabled-span': showReplaceLoader || showFilterLoader || loadedSearchResultsLength === 0
        }"
        (click)="onNextSearchResultClicked()"
      ></i>
      <button
        class="btn btn-primary btn-xsm ms-2"
        type="button"
        (click)="onReplaceClicked()"
        [disabled]="showReplaceLoader || showFilterLoader || loadedSearchResultsLength === 0"
      >
        {{ 'ResponseManagement::Replace' | abpLocalization }}
      </button>
      <button
        class="btn btn-primary btn-xsm ms-1"
        type="button"
        (click)="onReplaceAllClicked()"
        [disabled]="showReplaceLoader || showFilterLoader || loadedSearchResultsLength === 0"
      >
        {{ 'ResponseManagement::ReplaceAll' | abpLocalization }}
      </button>
    </div>
  </div></ng-template
>
