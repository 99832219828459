import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResponseManagementRoutingModule } from './response-management-routing.module';
import { ResponseManagementMainComponent } from './components/response-management-main/response-management-main.component';
import { ResponseManagementListComponent } from './components/response-management-list/response-management-list.component';
import { ResponseManagementFilterComponent } from './components/response-management-filter/response-management-filter.component';
import { PageModule } from '@abp/ng.components/page';
import { CoreModule } from '@abp/ng.core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule } from '@angular/forms';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { SestekCommonComponentsModule } from '@sestek/common/components';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { AuditLoggingModule } from '@volo/abp.ng.audit-logging';
import { SaasModule } from '@volo/abp.ng.saas';
import { AngularSplitModule } from 'angular-split';
import { BlockUIModule } from 'ng-block-ui';
import { SharedModule } from '../shared/shared.module';
import { NgbAccordionModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    ResponseManagementMainComponent,
    ResponseManagementListComponent,
    ResponseManagementFilterComponent,
  ],
  imports: [
    CommonModule,
    ResponseManagementRoutingModule,
    SharedModule,
    AuditLoggingModule,
    SaasModule,
    AngularSplitModule,
    PageModule,
    CommercialUiModule,
    CoreModule,
    FormsModule,
    NgxValidateCoreModule,
    BlockUIModule,
    NgbModule,
    NgbAccordionModule,
    ClipboardModule,
    CommonModule,
    NgbTooltipModule,
    NgSelectModule,
    NzPopoverModule,
    SestekCommonComponentsModule,
  ],
})
export class ResponseManagementModule {}
