import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ReplaceAllResponseModelDto,
  ReplaceResponseModelDto,
  ResponseManagementFilterOptionsDto,
  ResponseSearchFilterModelDto,
  ResponseSearchResultItemDto,
} from './response-management.model';

@Injectable({
  providedIn: 'root',
})
export class ResponseManagementService {
  apiName = 'Default';
  previousResultSelectionRequestedSubject = new Subject<void>();
  nextResultSelectionRequestedSubject = new Subject<void>();
  searchResultsLoadedSubject = new Subject<number>();
  replaceRequestedSubject = new Subject<string>();
  replaceAllRequestedSubject = new Subject<string>();
  replaceOperationCompletedSubject = new Subject<number>();
  replaceAllOperationCompletedSubject = new Subject<number>();
  replaceAllOperationFailedSubject = new Subject<void>();

  constructor(private restService: RestService) {}

  getFilterOptions = (projectId: string) =>
    this.restService.request<void, ResponseManagementFilterOptionsDto>(
      {
        method: 'GET',
        url: `/api/app/response-management/filter-options`,
        params: {
          projectId: projectId,
        },
      },
      { apiName: this.apiName }
    );

  search = (responseSearchFilter: ResponseSearchFilterModelDto) =>
    this.restService.request<ResponseSearchFilterModelDto, ResponseSearchResultItemDto[]>(
      {
        method: 'POST',
        url: `/api/app/response-management/search`,
        body: responseSearchFilter,
      },
      { apiName: this.apiName }
    );

  replace = (replaceResponseModel: ReplaceResponseModelDto) =>
    this.restService.request<ReplaceResponseModelDto, string>(
      {
        method: 'POST',
        url: `/api/app/response-management/replace`,
        body: replaceResponseModel,
      },
      { apiName: this.apiName }
    );

  replaceAll = (replaceResponseModelList: ReplaceAllResponseModelDto[]) =>
    this.restService.request<ReplaceAllResponseModelDto[], number>(
      {
        method: 'POST',
        url: `/api/app/response-management/replace-all`,
        body: replaceResponseModelList,
      },
      { apiName: this.apiName }
    );
}
