<div class="card mb-0" *blockUI="'results-grid-block-ui'">
  <div class="card-body position-relative pb-0 search-results">
    <form [formGroup]="rowsForm">
      <ngx-datatable
        default
        [rows]="tableDataSource"
        trackByProp="'textId'"
        [messages]="getTableMessages()"
      >
        <ngx-datatable-column
          name="{{ '::Title:ResponseManagement:Response' | abpLocalization }}"
          [sortable]="false"
          [width]="450"
        >
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <cai-rich-text-editor
              [required]="false"
              [content]="row.text"
              [spaceAfterInsert]="true"
              [trim]="true"
              [readOnly]="true"
              [isRichTextSupported]="true"
              [isPercentIconVisible]="false"
              #richTextInput
            ></cai-rich-text-editor>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ '::Title:ResponseManagement:Flow' | abpLocalization }}"
          [sortable]="false"
        >
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            {{ row.flowName }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ '::Title:ResponseManagement:NodeType' | abpLocalization }}"
          [sortable]="false"
        >
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            {{ row.nodeType }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ '::Title:ResponseManagement:ResponseType' | abpLocalization }}"
          [sortable]="false"
        >
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            {{ 'NodePalette::' + row.responseType | abpLocalization }}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </form>
  </div>
</div>
