import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ContentChange, QuillEditorComponent, QuillModules } from 'ngx-quill';
import { RICH_TEXT_EDITOR_EMPTY_VALUE } from '../../proxy/rich-text-editor.const';
import { MentionModel, QuillValueModel } from '../../proxy/rich-text-editor.model';

@Component({
  selector: 'cai-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RichTextEditorComponent implements OnInit {
  @Input() availableMentions: MentionModel[] = [];
  @Input() placeholder = '';
  @Input() required = false;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() isRichTextSupported = false;
  @Input() spaceAfterInsert = true;
  @Input() trim = false;
  @Input() autofocus = false;
  @Input() readOnly = false;
  @Input() isPercentIconVisible = true;
  @Input() content: string;

  @Output() contentChanged = new EventEmitter<ContentChange>();
  @Output() addMention: EventEmitter<void> = new EventEmitter();
  @Output() editMention: EventEmitter<MentionModel> = new EventEmitter();
  @Output() removeMention: EventEmitter<MentionModel> = new EventEmitter();

  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;

  formats: string[] = ['mention', 'bold', 'italic', 'underline', 'link', 'background'];
  modules: QuillModules = {
    mention: {
      allowedChars: /^[A-Za-z0-9\[\]\.]*$/,
      mentionDenotationChars: ['%'],
      showDenotationChar: false,
      dataAttributes: ['key', 'type'],
      onSelect: (item) => this.insertItem(item, false),
      source: (searchTerm, renderList) => {
        let availableMentions = this.availableMentions;

        if (searchTerm.length) {
          availableMentions = availableMentions.filter(
            (mention) => mention.key.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );

          availableMentions.unshift({
            key: searchTerm,
            value: searchTerm,
            type: 'custom',
          });
        }

        renderList(availableMentions, searchTerm);
      },
      renderItem: (item) => {
        return `${item.key}`;
      },
    },
    toolbar: [['bold', 'italic', 'underline', 'link', 'clean']],
  };

  isInvalid = false;
  showMentionListModal = false;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = {
        writeValue: () => {},
        registerOnChange: () => {},
        registerOnTouched: () => {},
      };
    }
  }

  ngOnInit() {
    if (!this.isRichTextSupported) {
      this.modules.toolbar = false;
      this.formats = ['mention'];
    }

    if (this.spaceAfterInsert === undefined) {
      this.spaceAfterInsert = true;
    }

    this.modules.mention.spaceAfterInsert = this.spaceAfterInsert;

    if (this.ngControl) {
      this.setQuillValue();

      this.ngControl.control.valueChanges.subscribe(() => {
        this.setQuillValue();
      });
    }
  }

  setQuillValue() {
    let quillValue: QuillValueModel;
    try {
      quillValue = JSON.parse(this.ngControl.control.value);
    } catch {}

    if (quillValue && quillValue.ops && quillValue.ops.length) {
      const lastOp = quillValue.ops[quillValue.ops.length - 1];
      if (typeof lastOp.insert === 'string') {
        lastOp.insert += '\n';
      }
    } else {
      quillValue = { ops: [{ insert: this.ngControl.control.value || '' }] };

      if (this.ngControl.control.value === null || this.ngControl.control.value === undefined) {
        setTimeout(() => this.ngControl.control.setValue(''));
      }
    }

    if (this.content !== JSON.stringify(quillValue)) {
      this.content = JSON.stringify(quillValue);
    }
  }

  onContentChanged(contentChange: ContentChange) {
    // quill bug fixed - begin
    const mentionLength = contentChange.html?.match(/<span class="mention"/)?.length ?? 0;
    const denotationCharLength =
      contentChange.html?.match(/<span class="ql-mention-denotation-char"/)?.length ?? 0;

    if (mentionLength !== denotationCharLength) {
      // quill bug occurred
      contentChange.editor.setContents(RICH_TEXT_EDITOR_EMPTY_VALUE);
      return;
    }
    // end

    const firstOp = contentChange.content.ops[0];
    const lastOp = contentChange.content.ops[contentChange.content.ops.length - 1];
    let trim = this.trim;

    if (trim) {
      const insertOps = contentChange.delta.ops.filter((op) => op.insert !== undefined);
      let retainCount = contentChange.delta.ops.find((op) => op.retain !== undefined)?.retain;
      const deleteCount = contentChange.delta.ops.find((op) => op.delete !== undefined)?.delete;

      trim =
        insertOps.length !== 1 ||
        !this.modules.mention.mentionDenotationChars.some((c) => c === insertOps[0].insert);

      if (trim && retainCount > 0 && deleteCount === 1) {
        contentChange.oldDelta.ops.forEach((op) => {
          if (typeof op.insert === 'string') {
            if (retainCount <= op.insert.length) {
              trim = !this.modules.mention.mentionDenotationChars.some(
                (c) => c === op.insert.charAt(retainCount)
              );
            }

            retainCount -= op.insert.length;
          } else {
            retainCount--;
          }
        });
      }
    }

    if (firstOp && typeof firstOp.insert === 'string') {
      if (trim) {
        firstOp.insert = firstOp.insert.replace(/^\s+/g, '');
      }
    }

    if (lastOp && typeof lastOp.insert === 'string') {
      if (lastOp.insert.endsWith('\n')) {
        lastOp.insert = lastOp.insert.slice(0, -1);
      }

      if (trim) {
        lastOp.insert = lastOp.insert.replace(/\s+$/g, '');
      }
    }

    if (this.ngControl) {
      const value = JSON.stringify(contentChange.content);

      if (value !== this.ngControl.control.value) {
        this.ngControl.control.setValue(value);

        this.contentChanged.emit(contentChange);
      }
    }
  }

  onAddMention() {
    this.addMention.emit();
  }

  onEditMention(mention: MentionModel) {
    this.editMention.emit(mention);
  }

  onRemoveMention(mention: MentionModel) {
    this.removeMention.emit(mention);
  }

  insertItem(mention: MentionModel, programmaticInsert: boolean) {
    const entry: any = { ...mention };

    delete entry.index;

    const mentionModule = this.editor.quillEditor.getModule('mention');

    mentionModule.cursorPos =
      mentionModule.cursorPos ?? this.editor.quillEditor.scroll.length() - 1;

    mentionModule.insertItem(entry, programmaticInsert);
  }

  onMentionSelected(mention: MentionModel) {
    this.insertItem(mention, true);
  }

  toggleMentionListModal() {
    this.showMentionListModal = !this.showMentionListModal;
  }

  setFocus(editor) {
    if (this.autofocus) {
      editor.focus();
    }
  }
}
