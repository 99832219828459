export interface NameIdPairDto {
  id?: string;
  name?: string;
}

export interface ResponseManagementFilterOptionsDto {
  flows: NameIdPairDto[];
  nodeTypes: NameIdPairDto[];
  responseTypes: NameIdPairDto[];
}

export interface ResponseSearchFilterModelDto {
  projectId: string;
  text: string;
  flowId?: string;
  nodeTypeId?: string;
  responseTypeId?: string;
  matchCase?: boolean;
  matchWholeWord?: boolean;
}

export interface ResponseSearchResultItemDto {
  textId?: string;
  text?: string;
  plainText?: string;
  flowName?: string;
  flowId?: string;
  nodeType?: string;
  nodeTypeId?: string;
  responseType?: string;
  responseTypeId?: string;
}

export interface ReplaceAllResponseModelDto {
  textId?: string;
  flowId?: string;
  nodeTypeId?: string;
  responseTypeId?: string;
  matchCase?: boolean;
  matchWholeWord?: boolean;
  oldText?: string;
  newText?: string;
}

export interface ReplaceResponseModelDto extends ReplaceAllResponseModelDto {
  matchIndex: number;
}

export enum TextHighlightType {
  result = 0,
  selected = 1,
}
