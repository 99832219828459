import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject, Subject, filter, finalize, switchMap } from 'rxjs';
import { ConfigStateService, RoutesService, eLayoutType } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { ProjectService } from 'src/app/projects/proxy/project.service';
import {
  ProjectFlowSummaryDto,
  ProjectFlowsAndVirtualAgentsCacheModel,
  ProjectFlowsAndVirtualAgentsDto,
} from 'src/app/projects/proxy/project.models';
import { PROJECT_FLOWS_AND_VIRTUAL_AGENTS_KEY, TOASTER_LIFE } from '../shared.consts';
import { getStorageKey } from '../designer/utils';
import { CAI } from './cai-routes.model';
import { FlowCreateDto, FlowType, Flows } from '../designer/proxy/designer.model';
import {
  ADD_PATH,
  CONVERSATIONS_PATH,
  CONVERSATIONS_ROUTE_ID,
  CONVERSATIONS_ROUTE_NAME,
  CREATE_SUBFLOW_ROUTE_ID,
  CREATE_SUBFLOW_ROUTE_NAME,
  DASHBOARD_PATH,
  DASHBOARD_ROUTE_ID,
  DASHBOARD_ROUTE_NAME,
  EDIT_PATH,
  INTENTS_PATH,
  PREMIUM_REPORTING_PATH,
  PREMIUM_REPORTING_ROUTE_NAME,
  PROJECTS_PATH,
  PROJECT_AND_PREMIUM_REPORTING_ROUTE_NAMES,
  PROJECT_ROOT_ROUTE_ID,
  PROJECT_ROOT_ROUTE_NAME,
  PROJECT_ROUTE_NAMES,
  RESPONSE_MANAGEMENT_PATH,
  RESPONSE_MANAGEMENT_ROUTE_ID,
  RESPONSE_MANAGEMENT_ROUTE_NAME,
  SCENARIO_DESIGN_PATH,
  SUBFLOW_TYPE_NAME,
  VERSIONS_PATH,
  VIRTUAL_AGENTS_PATH,
  VIRTUAL_AGENTS_ROOT_ROUTE_NAME,
  VIRTUAL_AGENT_ROOT_ROUTE_ID,
  VIRTUAL_AGENT_ROOT_ROUTE_NAME,
} from './cai-routes.consts';
import { DesignerService } from '../designer/proxy/designer.service';

@Injectable({ providedIn: 'root' })
export class CaiRoutesService {
  currentProjectFlowsAndVirtualAgents$ = new ReplaySubject<ProjectFlowsAndVirtualAgentsDto>(1);
  showSubflowUpdateForm$ = new Subject<ProjectFlowSummaryDto>();
  hideSubflowUpdateForm$ = new Subject<void>();
  updateSubFlow$ = new Subject<{ id: string; name: string }>();
  refreshProjectRoutes$ = new Subject<void>();
  isSubFlowSavingInProgress = false;

  private _currentProjectFlowsAndVirtualAgents: ProjectFlowsAndVirtualAgentsDto;
  get currentProjectFlowsAndVirtualAgents(): ProjectFlowsAndVirtualAgentsDto {
    return this._currentProjectFlowsAndVirtualAgents;
  }
  set currentProjectFlowsAndVirtualAgents(value: ProjectFlowsAndVirtualAgentsDto) {
    this._currentProjectFlowsAndVirtualAgents = value;
    this.currentProjectFlowsAndVirtualAgents$.next(value);
  }

  get currentProjectId() {
    return this.currentProjectFlowsAndVirtualAgents.projectId;
  }

  private set currentProjectId(projectId: string) {
    if (projectId !== this.currentProjectFlowsAndVirtualAgents?.projectId) {
      this.currentProjectFlowsAndVirtualAgents = {
        projectId,
        projectName: '',
        projectVersionId: '',
        flowSummaries: [],
        virtualAgentSummaries: [],
      };
    }
  }

  private get currentProjectVersionId() {
    return this.currentProjectFlowsAndVirtualAgents.projectVersionId;
  }

  private get projectRoutes() {
    return this.routesService.flat.filter((r) => {
      return PROJECT_ROUTE_NAMES.includes(r.name) || PROJECT_ROUTE_NAMES.includes(r.parentName);
    });
  }

  private get premiumReportingRoutes() {
    return this.routesService.flat.filter((r) => {
      return (
        PREMIUM_REPORTING_ROUTE_NAME === r.name || PREMIUM_REPORTING_ROUTE_NAME === r.parentName
      );
    });
  }

  private get otherRoutes() {
    return this.routesService.flat.filter((r) => {
      return (
        !PROJECT_AND_PREMIUM_REPORTING_ROUTE_NAMES.includes(r.name) &&
        !PROJECT_AND_PREMIUM_REPORTING_ROUTE_NAMES.includes(r.parentName)
      );
    });
  }

  private get isProjectFlowsMenuOpened(): boolean {
    return (
      this.currentUrlSegments[0] === PROJECTS_PATH &&
      this.currentUrlSegments[2] === VERSIONS_PATH &&
      this.currentUrlSegments[4]?.startsWith(SCENARIO_DESIGN_PATH) &&
      this.currentUrlSegments.length >= this.flowDesignerPageUrlSegmentsLength
    );
  }

  private get isProjectEditMenuOpened(): boolean {
    return this.currentUrlSegments[0] === PROJECTS_PATH && this.currentUrlSegments[2] === EDIT_PATH;
  }

  private get isProjectCreateMenuOpened(): boolean {
    return this.currentUrlSegments[0] === PROJECTS_PATH && this.currentUrlSegments[1] === ADD_PATH;
  }

  private get isVirtualAgentMenuOpened(): boolean {
    return (
      this.currentUrlSegments[0] === PROJECTS_PATH &&
      this.currentUrlSegments[2] === VIRTUAL_AGENTS_PATH &&
      this.currentUrlSegments.length >= this.virtualAgentDetailPageUrlSegmentsLength
    );
  }

  private get isOtherProjectMenuOpened(): boolean {
    return (
      this.currentUrlSegments[2] === DASHBOARD_PATH ||
      this.currentUrlSegments[2] === CONVERSATIONS_PATH ||
      this.currentUrlSegments[2] === RESPONSE_MANAGEMENT_PATH ||
      this.currentUrlSegments[2] === PREMIUM_REPORTING_PATH
    );
  }

  private initialRoutes: { name: string; invisible: boolean }[];
  private currentUrlSegments: string[];
  private virtualAgentDetailPageUrlSegmentsLength = 4;
  private flowDesignerPageUrlSegmentsLength = 5;

  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private routesService: RoutesService,
    private configStateService: ConfigStateService,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService,
    private designerService: DesignerService
  ) {
    this.currentProjectId = '';

    this.updateSubFlow$.subscribe((flow) => this.updateSubFlow(flow));

    this.refreshProjectRoutes$.subscribe(() => this.refreshProjectRoutes());

    this.routesService.flat$.subscribe(() => {
      this.premiumReportingRoutes.forEach((route) => {
        route.invisible = !this.currentProjectId;

        if (this.currentProjectId) {
          const urlSegments = route.path.split('/').filter((s) => s);

          if (urlSegments[2] === PREMIUM_REPORTING_PATH) {
            urlSegments.shift();
            urlSegments.shift();
          }

          urlSegments.unshift('/projects', this.currentProjectId);

          route.path = urlSegments.join('/');
        }
      });
    });
  }

  refreshRoute() {
    if (!this.initialRoutes) {
      this.initialRoutes = this.routesService.flat.map((r) => ({
        name: r.name,
        invisible: r.invisible,
      }));
    }

    this.currentUrlSegments = this.router.url.split('/').filter((element) => element);

    if (
      this.isProjectEditMenuOpened ||
      this.isProjectFlowsMenuOpened ||
      this.isVirtualAgentMenuOpened ||
      this.isOtherProjectMenuOpened
    ) {
      const isProjectChanged = this.currentProjectId !== this.currentUrlSegments[1];

      if (isProjectChanged) {
        this.currentProjectId = this.currentUrlSegments[1];
        this.showNewProjectRoutes();
      }
    } else if (this.isProjectCreateMenuOpened) {
      this.currentProjectId = '';
      this.resetRoutesToInitial();
    } else {
      this.currentProjectId = '';
      this.resetRoutesToInitial();
    }

    this.routesService.refresh();
  }

  private showNewProjectRoutes() {
    // Hide initial routes
    this.otherRoutes.forEach((route) => (route.invisible = true));

    // Show Premium Reporting routes
    this.premiumReportingRoutes.forEach((route) => (route.invisible = false));

    // Replace old projects routes with new ones from cache
    this.replaceProjectRoutes();

    // Load new projects routes
    this.refreshProjectRoutes();
  }

  private resetRoutesToInitial() {
    // Remove projects routes
    this.removeProjectRoutes();

    //reset initial routes visibility
    this.otherRoutes.forEach((route) => {
      const initialRoute = this.initialRoutes.find((ir) => ir.name === route.name);

      if (initialRoute) {
        route.invisible = initialRoute.invisible;
      } else {
        route.invisible = true;
      }
    });
    this.premiumReportingRoutes.forEach((route) => (route.invisible = true));
  }

  private replaceProjectRoutes() {
    this.removeProjectRoutes();
    this.routesService.add(this.buildProjectRoutes(this.currentProjectId));
  }

  private refreshProjectRoutes() {
    this.projectService.getProjectFlowsAndVirtualAgents(this.currentProjectId).subscribe({
      next: (result) => {
        this.currentProjectFlowsAndVirtualAgents = result;
        this.cacheProjectFlowsAndVirtualAgents(result);
        this.replaceProjectRoutes();
      },
    });
  }

  private removeProjectRoutes() {
    const routesToBeRemoved = this.projectRoutes.map((route) => route.name);

    this.routesService.remove(routesToBeRemoved);
  }

  private buildProjectRoutes(projectId: string): CAI.Route[] {
    const projectRootRoute: CAI.Route = {
      id: PROJECT_ROOT_ROUTE_ID,
      name: PROJECT_ROOT_ROUTE_NAME,
      layout: eLayoutType.application,
      iconClass: 'fa-sharp fa-regular fa-diagram-project',
      order: 0,
      requiredPolicy: 'Designer.Projects',
    };
    const virtualAgentRootRoute: CAI.Route = {
      id: VIRTUAL_AGENT_ROOT_ROUTE_ID,
      name: VIRTUAL_AGENT_ROOT_ROUTE_NAME,
      layout: eLayoutType.application,
      iconClass: 'fa fa-users',
      order: 1,
      requiredPolicy: 'Designer.Projects',
    };
    const routes: CAI.Route[] = [
      projectRootRoute,
      virtualAgentRootRoute,
      {
        id: RESPONSE_MANAGEMENT_ROUTE_ID,
        name: RESPONSE_MANAGEMENT_ROUTE_NAME,
        path: `${PROJECTS_PATH}/${projectId}/${RESPONSE_MANAGEMENT_PATH}`,
        layout: eLayoutType.application,
        iconClass: 'fa-sharp fa-regular fa-message-pen',
        order: 2,
        requiredPolicy: 'Designer.Projects',
      },
      {
        id: DASHBOARD_ROUTE_ID,
        name: DASHBOARD_ROUTE_NAME,
        path: `${PROJECTS_PATH}/${projectId}/${DASHBOARD_PATH}`,
        iconClass: 'fas fa-chart-line',
        layout: eLayoutType.application,
        order: 3,
        requiredPolicy: 'Designer.Dashboard.Tenant',
      },
      {
        id: CONVERSATIONS_ROUTE_ID,
        name: CONVERSATIONS_ROUTE_NAME,
        path: `${PROJECTS_PATH}/${projectId}/${CONVERSATIONS_PATH}`,
        layout: eLayoutType.application,
        iconClass: 'fas fa-comments-alt',
        order: 4,
        requiredPolicy: 'Designer.Conversations',
      },
    ];

    const cachedValues = this.getCachedProjectFlowsAndVirtualAgents(projectId);

    if (cachedValues?.projectFlowsAndVirtualAgents) {
      const projectVersionId = cachedValues.projectFlowsAndVirtualAgents.projectVersionId;

      cachedValues.projectFlowsAndVirtualAgents.flowSummaries.forEach((flow, order) => {
        const projectFlowRoute = this.buildProjectFlowRoute(
          flow,
          projectId,
          projectVersionId,
          projectRootRoute.name,
          order
        );

        routes.push(projectFlowRoute);
      });

      const createSubFlowRoute: CAI.Route = {
        id: CREATE_SUBFLOW_ROUTE_ID,
        name: CREATE_SUBFLOW_ROUTE_NAME,
        layout: eLayoutType.application,
        parentName: projectRootRoute.name,
        order: 1000,
        requiredPolicy: 'Designer.Projects',
        onClick: () => this.createSubFlow(),
      };

      routes.push(createSubFlowRoute);

      if (cachedValues.projectFlowsAndVirtualAgents.virtualAgentSummaries.length === 1) {
        const virtualAgent = cachedValues.projectFlowsAndVirtualAgents.virtualAgentSummaries[0];

        virtualAgentRootRoute.id = virtualAgent.id;
        virtualAgentRootRoute.path = `${PROJECTS_PATH}/${projectId}/${VIRTUAL_AGENTS_PATH}/${virtualAgent.id}/${virtualAgent.name}/${INTENTS_PATH}`;
      } else {
        virtualAgentRootRoute.name = VIRTUAL_AGENTS_ROOT_ROUTE_NAME;

        cachedValues.projectFlowsAndVirtualAgents.virtualAgentSummaries.forEach(
          (virtualAgent, order) => {
            const virtualAgentRoute: CAI.Route = {
              id: virtualAgent.id,
              name: virtualAgent.name,
              path: `${PROJECTS_PATH}/${projectId}/${VIRTUAL_AGENTS_PATH}/${virtualAgent.id}/${virtualAgent.name}/${INTENTS_PATH}`,
              layout: eLayoutType.application,
              parentName: virtualAgentRootRoute.name,
              order,
              requiredPolicy: 'Designer.Projects',
            };

            routes.push(virtualAgentRoute);
          }
        );
      }
    }

    return routes;
  }

  private buildProjectFlowRoute(
    flow: { id: string; name: string; flowType: FlowType },
    projectId: string,
    projectVersionId: string,
    parentName: string,
    order: number
  ): CAI.Route {
    const projectFlowRoute: CAI.Route = {
      id: flow.id,
      name: flow.name,
      path: `${PROJECTS_PATH}/${projectId}/${VERSIONS_PATH}/${projectVersionId}/${SCENARIO_DESIGN_PATH}/${flow.id}`,
      layout: eLayoutType.application,
      parentName: parentName,
      order,
      requiredPolicy: 'Designer.Projects',
      actions:
        flow.flowType === FlowType.subFlow
          ? [
              {
                name: '::Rename',
                iconClass: 'fa fa-edit text-secondary',
                requiredPolicy: 'Designer.Flows.Edit',
                onClick: () => {
                  const updatedFlow = this.currentProjectFlowsAndVirtualAgents.flowSummaries.find(
                    (f) => f.id === flow.id
                  );
                  this.showSubflowUpdateForm$.next(updatedFlow);
                },
              },
              {
                name: 'AbpUi::Delete',
                iconClass: 'fa fa-trash text-secondary',
                requiredPolicy: 'Designer.Flows.Edit',
                onClick: () => {
                  const updatedFlow = this.currentProjectFlowsAndVirtualAgents.flowSummaries.find(
                    (f) => f.id === flow.id
                  );

                  this.deleteSubFlow(updatedFlow);
                },
              },
            ]
          : [],
    };

    return projectFlowRoute;
  }

  private getCachedProjectFlowsAndVirtualAgents(
    projectId: string
  ): ProjectFlowsAndVirtualAgentsCacheModel {
    const storageKey = getStorageKey(this.configStateService, PROJECT_FLOWS_AND_VIRTUAL_AGENTS_KEY);
    const cachedValuesStr = localStorage.getItem(storageKey);

    return cachedValuesStr ? JSON.parse(cachedValuesStr)[projectId] : null;
  }

  private cacheProjectFlowsAndVirtualAgents(value: ProjectFlowsAndVirtualAgentsDto) {
    const storageKey = getStorageKey(this.configStateService, PROJECT_FLOWS_AND_VIRTUAL_AGENTS_KEY);
    let cachedValuesStr = localStorage.getItem(storageKey);
    let cachedValues = new Map<string, ProjectFlowsAndVirtualAgentsCacheModel>();
    if (cachedValuesStr) {
      cachedValues = JSON.parse(cachedValuesStr);
    }

    const cachedValue = cachedValues[value.projectId] ?? {};

    cachedValue.projectFlowsAndVirtualAgents = value;
    cachedValues[value.projectId] = cachedValue;
    cachedValuesStr = JSON.stringify(cachedValues);
    localStorage.setItem(storageKey, cachedValuesStr);
  }

  private createSubFlow() {
    this.isSubFlowSavingInProgress = true;

    const subFlowCount = this.currentProjectFlowsAndVirtualAgents.flowSummaries.filter(
      (flow) => flow.flowType === FlowType.subFlow
    ).length;
    const subflowName = this.generateSubFlowName(subFlowCount + 1);

    const flowCreateDto: FlowCreateDto = {
      projectVersionId: this.currentProjectVersionId,
      name: subflowName,
      flowType: FlowType.subFlow,
    };

    this.designerService
      .createSubFlow(flowCreateDto)
      .pipe(finalize(() => (this.isSubFlowSavingInProgress = false)))
      .subscribe({
        next: (flow: Flows) => {
          this.toasterService.success('LeptonThemeManagement::SuccessfullySaved', '', {
            life: TOASTER_LIFE,
          });

          const parentName = this.routesService.flat.find(
            (route) => route.name === PROJECT_ROOT_ROUTE_NAME
          ).name;
          const order =
            Math.max(
              ...this.routesService.flat
                .filter(
                  (route: CAI.Route) =>
                    route.parentName === parentName && route.id !== CREATE_SUBFLOW_ROUTE_ID
                )
                .map((route) => route.order)
            ) + 1;

          const projectFlowRoute = this.buildProjectFlowRoute(
            flow,
            this.currentProjectId,
            this.currentProjectVersionId,
            parentName,
            order
          );

          this.routesService.add([projectFlowRoute]);
          this.currentProjectFlowsAndVirtualAgents.flowSummaries.push({
            id: flow.id,
            name: flow.name,
            reducedName: flow.name,
            flowType: flow.flowType,
          });
          this.cacheProjectFlowsAndVirtualAgents(this.currentProjectFlowsAndVirtualAgents);

          this.router.navigate([
            PROJECTS_PATH,
            this.currentProjectId,
            VERSIONS_PATH,
            this.currentProjectVersionId,
            SCENARIO_DESIGN_PATH,
            flow.id,
          ]);
        },
      });
  }

  private generateSubFlowName(currentNumber: number) {
    const generatedName = SUBFLOW_TYPE_NAME + ' ' + currentNumber;

    const isExist = this.currentProjectFlowsAndVirtualAgents.flowSummaries.some(
      (flow) => flow.name === generatedName
    );

    return isExist ? this.generateSubFlowName(currentNumber + 1) : generatedName;
  }

  private updateSubFlow(flow: { id: string; name: string }) {
    this.isSubFlowSavingInProgress = true;

    this.designerService
      .updateSubFlowName(flow.id, { name: flow.name })
      .pipe(finalize(() => (this.isSubFlowSavingInProgress = false)))
      .subscribe({
        next: (updatedFlow: Flows) => {
          this.toasterService.success('LeptonThemeManagement::SuccessfullySaved', '', {
            life: TOASTER_LIFE,
          });

          this.hideSubflowUpdateForm$.next();

          this.currentProjectFlowsAndVirtualAgents.flowSummaries.find(
            (f) => f.id === updatedFlow.id
          ).name = updatedFlow.name;

          this.cacheProjectFlowsAndVirtualAgents(this.currentProjectFlowsAndVirtualAgents);

          const updatedRoute = this.routesService.flat.find(
            (route: CAI.Route) => route.id === updatedFlow.id
          );

          this.routesService.patch(updatedRoute.name, { name: updatedFlow.name });
        },
      });
  }

  private deleteSubFlow(flow: ProjectFlowSummaryDto) {
    this.confirmationService
      .warn('::DeleteConfirmationMessageWithName', '::DeleteRecordConfirmationMessage', {
        messageLocalizationParams: [flow.name],
      })
      .pipe(
        filter((status) => status === Confirmation.Status.confirm),
        switchMap(() => this.designerService.deleteSubFlow(flow.id))
      )
      .subscribe(() => {
        const deletedRoute = this.routesService.flat.find((route) => route.name === flow.name);
        this.routesService.remove([flow.name]);
        this.currentProjectFlowsAndVirtualAgents.flowSummaries =
          this.currentProjectFlowsAndVirtualAgents.flowSummaries.filter((f) => f.id !== flow.id);
        this.cacheProjectFlowsAndVirtualAgents(this.currentProjectFlowsAndVirtualAgents);

        // Check if the current sub flow is deleted
        if (this.isProjectFlowsMenuOpened && this.currentUrlSegments[5] === flow.id) {
          const previousRoute = this.routesService.flat
            .filter(
              (route) =>
                route.parentName === deletedRoute.parentName && route.order < deletedRoute.order
            )
            .pop();
          const previousFlow = this.currentProjectFlowsAndVirtualAgents.flowSummaries.find(
            (f) => f.name === previousRoute.name
          );

          this.router.navigate([
            PROJECTS_PATH,
            this.currentProjectId,
            VERSIONS_PATH,
            this.currentProjectVersionId,
            SCENARIO_DESIGN_PATH,
            previousFlow.id,
          ]);
        }
      });
  }
}
