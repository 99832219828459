import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResponseSearchFilterModelDto } from '../../proxy/response-management.model';
import { ActivatedRoute } from '@angular/router';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { componentDestroyed, isRtl } from '@utils';
import { filter, takeUntil } from 'rxjs';
import { CaiRoutesService } from 'src/app/shared/routes/cai-routes.service';

@Component({
  selector: 'cai-response-management-main',
  templateUrl: './response-management-main.component.html',
  styleUrls: ['./response-management-main.component.scss'],
})
export class ResponseManagementMainComponent implements OnInit, OnDestroy {
  filterValue: ResponseSearchFilterModelDto;
  projectId: string;
  breadcrumbItems = [];
  isRtl: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private localizationService: LocalizationService,
    private configStateService: ConfigStateService,
    private caiRoutesService: CaiRoutesService
  ) {}

  ngOnInit() {
    this.isRtl = isRtl(this.configStateService);

    this.activatedRoute.params.subscribe((params) => (this.projectId = params.projectId));

    this.caiRoutesService.currentProjectFlowsAndVirtualAgents$
      .pipe(
        takeUntil(componentDestroyed(this)),
        filter((value) => !!value.projectName && !!value.projectId && !!value.projectVersionId)
      )
      .subscribe((value) => {
        this.breadcrumbItems = [
          { name: '::Menu:Projects', path: '/projects' },
          {
            name: value.projectName,
            path: `/projects/${value.projectId}/versions/${value.projectVersionId}/scenario-design`,
          },
          { name: this.localizationService.instant('::Menu:ResponseManagement') },
        ];
      });
  }

  onFiltersChanged(filterValueModel: ResponseSearchFilterModelDto) {
    this.filterValue = filterValueModel;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() {}
}
