export const PREFIX = 'OpenAi';
export const PROVIDER = PREFIX + '.Provider';
export const API_KEY = PREFIX + '.ApiKey';
export const HAS_EXISTING_API_KEY = PREFIX + '.HasExistingApiKey';
export const EMBEDDING_NAME = PREFIX + '.EmbeddingName';

export const MODEL = PREFIX + '.Model';

export const SERVICE_URL = PREFIX + '.ServiceUrl';
export const DEPLOYMENT_NAME = PREFIX + '.DeploymentName';
export const RESOURCE_NAME = PREFIX + '.ResourceName';
export const EMBEDDING_DEPLOYMENT = PREFIX + '.EmbeddingDeployment';

export const UTTERANCE_GENERATION_PHRASE =
  'Imagine that you are a chatbot and give me {0} utterances that user can say to you in {1}\ndescription: {2}\nintent name:{3}\n\nutterances:\n\n';
export const CONVERSATION_SUMMARIZATION_PHRASE =
  "###{0}\n###\nYour task is to generate a short summary of a call center dialog.\nInstructions:\nSummarize the main points of the above dialog, delimited by ###, in at most 45 words.\nYour answer must be in the conversation's language.";
export const TEXT_RESPONSE_GENERATION_PHRASE =
  'Imagine that you are a chatbot and give me 1 utterance that chatbot can say to user similar to "{0},{1},{2}" sentence(s). Answer in language of "{0},{1},{2}"';

export const PROJECT_GENERATION_PHRASE =
  'Act as an intent and utterance generator that outputs in JSON format that represents an object with a key-value pair where the key is "intents", and the value is an array containing objects representing individual intents. Each intent object has two key-value pairs: "name", representing the name of the intent(without spaces), and "utterances", representing an array of sample phrases that users might say to express that intent. Return Json format not as markdown. Amounts: {0} intents, {1} utterances for each intent. Give intent and utterances in {2}. Context: {3}';

export const MODEL_OPTIONS = [
  'gpt-4o',
  'gpt-4-turbo',
  'gpt-4-turbo-preview',
  'gpt-4-1106-preview',
  'gpt-4-32k',
  'gpt-4',
  'gpt-3.5-turbo-instruct',
  'gpt-3.5-turbo-16k',
  'gpt-3.5-turbo',
];

export const EMBEDDING_NAME_OPTIONS = [
  'text-embedding-3-small',
  'text-embedding-3-large',
  'text-embedding-ada-002',
];
