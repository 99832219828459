import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  MessageAttachmentCategory,
  NamedEntitySourceType,
  NamedEntityType,
} from 'src/app/conversation/proxy/chat.model';
import { GridConfig } from 'src/app/shared/column-configurator/models/column-configurator.model';
import { FlowType } from 'src/app/shared/designer/proxy/designer.model';

export interface GetConversationListDto {
  customerInformationType: CustomerInformationType;
  customerInformation: string;
  projectIds?: string[];
  intentIds?: string[];
  channelNames?: string[];
  ratings?: number[];
  sentimentLevels?: number[];
  startDate?: Date;
  endDate?: Date;
}

export interface ConversationFilterValueModel extends GetConversationListDto {
  conversationId?: string;
}

export interface SessionDto {
  date: string;
  projectName: string;
  externalConversationId: string;
  conversationSessionId: string;
  channelName: string;
  channelId: string;
  duration: number;
  lastIntent: string;
  averageSentimentScore: number;
  sentimentLevel: SentimentLevel;
  conversationSummary: string;
  customerName: string;
  customerEmailAddress: string;
  customerPhoneNumber: string;
  customerTwitterAddress: string;
  lastRatingScore: number;
}

export enum CustomerInformationType {
  Phone,
  Name,
  Email,
  Twitter,
}

export enum SentimentLevel {
  VeryNegative,
  Negative,
  Neutral,
  Positive,
  VeryPositive,
}

export enum TranslationStatus {
  Success,
  Failed,
  Skipped,
}

export interface SessionDetailDto {
  session: SessionDto;
  messages: MessageDto[];
  lastRating: RatingDto;
}

export interface MessageDto {
  id: string;
  text: string;
  translatedText: string;
  dateCreated: Date;
  type: number;
  source: number;
  translationStatus?: number;
  contentType: number;
  storagePath: string;
  jsonContent: string;
  translatedJsonContent: string;
  sentimentScore: number;
  sentimentLevel: SentimentLevel;
  srConfidence: number;
  isSrConfidenceBelowThreshold: boolean;
  hasRecognitionAmbiguity: boolean;
  flowName: string;
  flowType: FlowType;
  recognitions: RecognitionDto[];
  extractedNamedEntities: ExtractedNamedEntityDto[];
  messageAttachments: MessageAttachmentDto[];
}

export interface RecognitionDto {
  intentName: string;
  confidence: number;
}

export interface MessageAttachmentDto {
  messageId: string;
  category: MessageAttachmentCategory;
  contentType: string;
  extension: string;
  originalName: string;
  storageObjectName: string;
  sizeInBytes: number;
  dateCreated: Date;
  dateDeleted: Date;
  isDeleted: boolean;
}

export interface RatingDto {
  id: string;
  externalConversationId: string;
  conversationSessionId: string;
  rating: number;
  comment: string;
  dateProcessed: Date;
}

export interface ExtractedNamedEntityDto {
  name: string;
  value: string;
  synonym: string;
  type: NamedEntityType;
  sourceType: NamedEntitySourceType;
}

export interface ActivityModel {
  type: string;
  text: string;
  entities: {
    type: string;
    address: string;
    geo: {
      elevation: string;
      latitude: number;
      longitude: number;
      type: string;
      name: string;
    };
    hasMap: string;
  }[];
  attachments: {
    contentType: string;
    content: string;
    name: string;
    contentUrl: string;
  }[];
  channelData: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    CustomActionData: string;
  };
}

export interface ActivityAttachmentContentModel {
  title?: string;
  subtitle?: string;
  text: string;
  images?: {
    url: string;
    alt: any;
    tap: any;
  }[];
  buttons: {
    type: string;
    title: string;
    image: any;
    text: any;
    displayText: any;
    value: string;
    channelData: any;
    imageAltText: any;
  }[];
  tap: any;
}

export type GetConversationListInput = PagedAndSortedResultRequestDto;

export interface CustomerInformationDto {
  personalInformation: CustomerPersonalInformationDto[];
  sentimentScore?: number;
  sentimentLevel?: SentimentLevel;
}

export interface CustomerPersonalInformationDto {
  key: string;
  value: string;
}

export const DEFAULT_CONVERSATION_LIST_CONFIG: GridConfig = {
  id: 'conversation-list',
  columns: [
    {
      name: 'date',
      header: 'Conversation::Date',
      locked: true,
      visible: true,
      sortable: false,
    },
    {
      name: 'externalConversationId',
      header: 'Conversation::ConversationId',
      locked: true,
      visible: true,
      sortable: false,
      width: 300,
    },
    {
      name: 'channelName',
      header: 'Conversation::Channel',
      locked: false,
      visible: true,
      sortable: false,
      width: 95,
    },
    {
      name: 'duration',
      header: 'Conversation::Duration',
      locked: false,
      visible: true,
      sortable: true,
      width: 120,
    },
    {
      name: 'lastIntent',
      header: 'Conversation::LastIntent',
      locked: false,
      visible: true,
      sortable: false,
    },
    {
      name: 'lastRatingScore',
      header: 'Conversation::Rating',
      locked: false,
      visible: false,
      sortable: true,
    },
    {
      name: 'averageSentimentScore',
      header: 'Conversation::CustomerSentiment',
      locked: false,
      visible: false,
      sortable: true,
    },
    {
      name: 'customerPhoneNumber',
      header: 'Conversation::CustomerInformationType:Phone',
      locked: false,
      visible: false,
      sortable: false,
    },
    {
      name: 'customerName',
      header: 'Conversation::CustomerInformationType:Name',
      locked: false,
      visible: false,
      sortable: true,
    },
    {
      name: 'customerEmailAddress',
      header: 'Conversation::CustomerInformationType:Email',
      locked: false,
      visible: false,
      sortable: false,
    },
    {
      name: 'customerTwitterAddress',
      header: 'Conversation::CustomerInformationType:Twitter',
      locked: false,
      visible: false,
      sortable: false,
    },
  ],
};
