import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ResponseSearchFilterModelDto } from '../../proxy/response-management.model';
import { Options } from '@popperjs/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ResponseManagementService } from '../../proxy/response-management.service';
import { Subscription, finalize } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ENTER_KEY_CODE } from 'src/app/shared/shared.consts';

@Component({
  selector: 'cai-response-management-filter',
  templateUrl: './response-management-filter.component.html',
  styleUrls: ['./response-management-filter.component.scss'],
})
export class ResponseManagementFilterComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  filterForm: UntypedFormGroup;
  replaceForm: UntypedFormGroup;
  projectFlows = [];
  projectNodeTypes = [];
  projectResponseTypes = [];

  showFilterLoader = false;
  filterValue: ResponseSearchFilterModelDto;

  isMatchCaseSelected = false;
  isMatchWholeWordSelected = false;

  loadedSearchResultsLength = 0;

  showReplaceLoader = false;

  @BlockUI('filter-panel-block-ui') blockUI: NgBlockUI;

  searchResultsLoadedSubjectSubscription: Subscription;
  replaceOperationCompletedSubjectSubscription: Subscription;
  replaceAllOperationCompletedSubjectSubscription: Subscription;
  replaceAllOperationFailedSubjectSubscription: Subscription;

  @Input() projectId: string;

  @ViewChild('searchBox', { read: ElementRef }) searchBox: ElementRef;
  @ViewChild('replacePanelPopover') replacePanelPopover: NgbPopover;

  replacePanelPopperOptions = (options: Partial<Options>) => {
    // customize modifiers
    for (const modifier of options.modifiers || []) {
      // customize offset
      if (modifier.name === 'offset' && modifier.options) {
        modifier.options.offset = () => [0, 0];
      }
    }

    // first update callback
    options.onFirstUpdate = (state) => {
      if (state.elements?.arrow) {
        state.elements.arrow.style.display = 'none';
      }
    };
    return options;
  };

  @Output()
  public filtersChanged: EventEmitter<ResponseSearchFilterModelDto> = new EventEmitter(true);

  constructor(
    private fb: UntypedFormBuilder,
    private responseManagementService: ResponseManagementService
  ) {}

  ngOnDestroy(): void {
    this.searchResultsLoadedSubjectSubscription?.unsubscribe();
    this.replaceOperationCompletedSubjectSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectId && changes.projectId.currentValue) {
      this.initializeComponentData();
    }
  }

  ngAfterViewInit(): void {
    this.replacePanelPopover.shown.subscribe(() => {
      const searchBoxWidth = this.searchBox.nativeElement.offsetWidth;
      const popoverWindow = document.querySelector('.replace-panel-popover');
      if (popoverWindow) {
        (popoverWindow as HTMLElement).style.width = `${searchBoxWidth}px`;
      }
    });
  }

  ngOnInit(): void {
    this.buildForms();

    this.searchResultsLoadedSubjectSubscription =
      this.responseManagementService.searchResultsLoadedSubject.subscribe(
        (loadedSearchResultsLength) => {
          this.loadedSearchResultsLength = loadedSearchResultsLength;
          this.showFilterLoader = false;
          this.blockUI.stop();
        }
      );

    this.replaceOperationCompletedSubjectSubscription =
      this.responseManagementService.replaceOperationCompletedSubject.subscribe(
        (resultLengthAfterReplace) => {
          this.loadedSearchResultsLength = resultLengthAfterReplace;
          this.showReplaceLoader = false;
          this.blockUI.stop();
        }
      );

    this.replaceAllOperationCompletedSubjectSubscription =
      this.responseManagementService.replaceAllOperationCompletedSubject.subscribe(
        (resultLengthAfterReplace) => {
          this.loadedSearchResultsLength = resultLengthAfterReplace;
          this.showReplaceLoader = false;
        }
      );

    this.replaceAllOperationFailedSubjectSubscription =
      this.responseManagementService.replaceAllOperationFailedSubject.subscribe(() => {
        this.blockUI.stop();
      });
  }

  initializeComponentData() {
    if (!this.blockUI.isActive) {
      this.blockUI.start();
    }

    this.responseManagementService
      .getFilterOptions(this.projectId)
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        })
      )
      .subscribe((filterOptionsResult) => {
        this.projectFlows = filterOptionsResult.flows;
        this.projectNodeTypes = filterOptionsResult.nodeTypes;
        this.projectResponseTypes = filterOptionsResult.responseTypes;
      });
  }

  buildForms() {
    this.filterForm = this.fb.group({
      text: '',
      flowId: null,
      nodeTypeId: null,
      responseTypeId: null,
      matchCase: false,
      matchWholeWord: false,
    });

    this.replaceForm = this.fb.group({
      replaceWith: '',
    });
  }

  validateSearchKeyword(control: FormControl) {
    if (control.value?.trim()?.length < 2) {
      return { searchKeywordShouldBeTwoOrMoreCharacters: true }; // Invalid
    } else {
      return null; // Valid
    }
  }

  onMatchWholeWordClicked() {
    this.isMatchWholeWordSelected = !this.isMatchWholeWordSelected;
    this.filterForm.get('matchWholeWord').patchValue(this.isMatchWholeWordSelected);

    if (this.filterForm.get('text')?.value?.trim()?.length < 2) {
      return;
    }

    this.emitFiltersChangedEvent();
  }

  onMatchCaseClicked() {
    this.isMatchCaseSelected = !this.isMatchCaseSelected;
    this.filterForm.get('matchCase').patchValue(this.isMatchCaseSelected);

    if (this.filterForm.get('text')?.value?.trim()?.length < 2) {
      return;
    }

    this.emitFiltersChangedEvent();
  }

  onPreviousSearchResultClicked() {
    if (this.loadedSearchResultsLength > 0) {
      this.responseManagementService.previousResultSelectionRequestedSubject.next();
    }
  }

  onNextSearchResultClicked() {
    if (this.loadedSearchResultsLength > 0) {
      this.responseManagementService.nextResultSelectionRequestedSubject.next();
    }
  }

  onReplaceClicked() {
    this.showReplaceLoader = true;
    if (!this.blockUI.isActive) {
      this.blockUI.start();
    }

    this.responseManagementService.replaceRequestedSubject.next(
      this.replaceForm.get('replaceWith').value
    );
  }

  onReplaceAllClicked() {
    this.showReplaceLoader = true;
    if (!this.blockUI.isActive) {
      this.blockUI.start();
    }

    this.responseManagementService.replaceAllRequestedSubject.next(
      this.replaceForm.get('replaceWith').value
    );
  }

  onKeydownForSearchBox(event) {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      this.onSubmitFilters();
    }
  }

  onClearFilters() {
    this.filterForm.reset();

    this.filterForm.setValue({
      text: '',
      flowId: null,
      nodeTypeId: null,
      responseTypeId: null,
      matchCase: false,
      matchWholeWord: false,
    });
  }

  onSubmitFilters() {
    if (this.filterForm.get('text')?.value?.trim()?.length < 2) {
      return;
    }

    this.showFilterLoader = true;

    this.emitFiltersChangedEvent();
  }

  private emitFiltersChangedEvent() {
    const filterModel: ResponseSearchFilterModelDto = this.filterForm.getRawValue();
    filterModel.text = filterModel.text.trim();
    filterModel.projectId = this.projectId;

    if (!this.blockUI.isActive) {
      this.blockUI.start();
    }

    this.filtersChanged.emit(filterModel);
  }
}
